import {Injectable} from '@angular/core';

const SERVER_URL = 'guides/stolpersteine_er';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  constructor() {
  }

  resolveUrl(relativePath: string): string {
    return SERVER_URL + '/' + relativePath;
  }

  getGuideUrl(): string {
    return SERVER_URL + '/guide.json';
  }

}
