import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GuideService} from '../../services/guide.service';
import {Category, Guide, Spot} from '../../core/model';

import * as L from 'leaflet';
import {ResourceService} from '../../services/resource.service';

@Component({
  selector: 'map',
  templateUrl: './map.component.html',
  styleUrls: [
    './map.component.less'
  ],
  providers: []
})
export class MapComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private viewContainerRef: ViewContainerRef,
              private guideService: GuideService, private resources: ResourceService) {

  }

  private overlayMaps;
  private guide: Guide;
  private title = '';
  private spotIdInFocus = '';
  private map: any;

  private static getPopupContent(spot: Spot): string {
    return '<h2>' + spot.name + '</h2><p><a  href=\'#/spot/' + spot._id +
      '\'><i class=\'fa fa-info-circle fa-lg\'></i> details</a></p>';
  }

  private static getPointForGeoJson(geojson) {
    if (geojson === undefined || geojson.geometry === undefined) {
      return {lat: 48.963625, lng: 2.309184};
    }
    let point;
    if (geojson.geometry.type === 'Polygon') {
      point = [geojson.geometry.coordinates[0][0][1], geojson.geometry.coordinates[0][0][0]];
    } else if (geojson.geometry.type === 'LineString') {
      point = [geojson.geometry.coordinates[0][1], geojson.geometry.coordinates[0][0]];
    } else {
      point = [geojson.geometry.coordinates[1], geojson.geometry.coordinates[0]];
    }
    return point;
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.spotIdInFocus = params.get('id');
      }
    });
    // remove map and re-initialize in order to prevent bug causing grey tiles.
    if (this.map) {
      this.map.remove();
    }
    this.guide = this.guideService.guide;
    this.title = this.guide.title;
    this.map = this.createMap();
    this.overlayMaps = this.createOverlayMaps();
  }


  private createMap() {

    const center = MapComponent.getPointForGeoJson(this.guide.mapcenter);
    const map = L.map('map', {
      zoomControl: false,
      center: center,
      tap: false,
      dragging: true,
      zoom: 16,
      minZoom: 4,
      maxZoom: 18,
      layers: []
    });
    map.addLayer(L.tileLayer(this.guide.map_server, {
      attribution: this.guide.map_attribution
    }));
    map.locate({setView: false, maxZoom: 16});
    map.on('locationfound', function onLocationFound(event: any) {
      L.circle(event.latlng, event.accuracy / 10).addTo(map);
    });
    L.control.zoom({position: 'topright'}).addTo(map);
    L.control.scale().addTo(map);
    return map;
  }


  private createMarker(spot: Spot, latlng) {
    const myIcon = L.icon({
      iconSize: [25, 25],
      iconUrl: this.resources.resolveUrl(spot.category.markerFilename)
    });
    return new L.Marker(latlng, {icon: myIcon, title: spot.name});
  }

  private bindPopup(spot: Spot, layer) {
    layer.bindPopup(MapComponent.getPopupContent(spot), {
      offset: L.point(1, 1),
      'className': 'popup'
    });
  }

  private createOverlayMaps() {
    const overlayMaps = {};
    this.guide.categories.forEach(category => {
      const markerLayer = this.createMarkerLayer(category);
      const categoryLayerGroup = L.layerGroup([markerLayer])
        .addTo(this.map);
      overlayMaps[category.name] = categoryLayerGroup;
    });
    return overlayMaps;
  }

  private onlyShowOneSpot(): boolean {
    return this.spotIdInFocus !== '';
  }

  private filter(spot: Spot): boolean {
    return this.onlyShowOneSpot() ? this.spotIdInFocus == spot._id : true;
  }

  private showPopupForSpotInFocus(latlng, spot: Spot) {
    L.popup({offset: L.point(0, -15)})
      .setLatLng(latlng)
      .setContent(MapComponent.getPopupContent(spot))
      .openOn(this.map);
    this.map.panTo(latlng);
    const scope = this;
    this.map.on('popupclose', function (e) {
      scope.spotIdInFocus = '';
      for (const key in scope.overlayMaps) {
        scope.map.removeLayer(scope.overlayMaps[key]);
      }
      scope.overlayMaps = scope.createOverlayMaps();
    });
  }

  private createMarkerLayer(category: Category) {
    const scope = this;
    const markerLayer = L.geoJSON(undefined, {
      pointToLayer: function (feature, latlng) {
        if (scope.onlyShowOneSpot()) {
          scope.showPopupForSpotInFocus(latlng, feature.properties.spot);
        }
        return scope.createMarker(feature.properties.spot, latlng);
      },
      onEachFeature: function (feature, layer) {
        return scope.bindPopup(feature.properties.spot, layer);
      },
      filter: function (feature: any): boolean {
        return scope.filter(feature.properties.spot);
      }
    });
    this.guide.spots.forEach(spot => {
      if (spot.category === category && spot.geolocation.properties) {
        const feature = spot.geolocation;
        feature.properties.spot = spot;
        markerLayer.addData(feature);
      }
    });
    return markerLayer;
  }

}
