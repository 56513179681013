import {Pipe, PipeTransform} from '@angular/core';
import {Spot} from "../core/model";

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(spots: Spot[], searchText: string): any {
    if (!spots) {
      return [];
    }
    if (!searchText) {
      return spots;
    }
    searchText = searchText.toLowerCase();
    return spots.filter(spot => {
      const spotContent = spot.description + ' ' + spot.name;
      return spotContent.toLowerCase().includes(searchText);
    });
  }

}
