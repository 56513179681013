import {Routes} from '@angular/router';

import {MapComponent} from './components/map/map.component';
import {SpotComponent} from './components/spot/spot.component';
import {GuideInfoComponent} from './components/guide-info/guide-info.component';

export const ROUTES: Routes = [
  {path: 'map/:id', component: MapComponent},
  {path: 'spot/:id', component: SpotComponent},
  {path: 'info', component: GuideInfoComponent},
  {path: '', component: MapComponent}
];
