import {Component, OnInit} from '@angular/core';
import {GuideService} from '../../services/guide.service';
import {Guide} from '../../core/model';

@Component({
  selector: 'app-guide-info',
  templateUrl: './guide-info.component.html',
  styleUrls: ['./guide-info.component.css']
})
export class GuideInfoComponent implements OnInit {

  private guide: Guide;

  constructor(guideService: GuideService) {
    this.guide = guideService.guide;
  }

  ngOnInit() {
  }

}
