import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Spot} from '../../core/model';
import {GuideService} from '../../services/guide.service';
import {ResourceService} from '../../services/resource.service';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from "ngx-gallery";

declare var window: any;

@Component({
  selector: 'app-spot',
  templateUrl: './spot.component.html',
  styleUrls: ['./spot.component.scss']
})
export class SpotComponent implements OnInit {

  private navigationLink = '#';
  spot: Spot;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[] = [];

  constructor(private route: ActivatedRoute,
              private resourceService: ResourceService,
              private guideService: GuideService) {
  }

  ngOnInit() {
    this.galleryOptions = [
      {
        width: '600px',
        height: '300px',
        thumbnailsColumns: 6,
        imageAnimation: NgxGalleryAnimation.Slide,
        imageDescription: true,
        previewDescription: true
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        imageDescription: true,
        previewDescription: true
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];
    this.route.paramMap.subscribe(
      params => {
        this.spot = this.guideService.getSpot(params.get('id'));
        const latlng = this.spot.geolocation.geometry.coordinates;
        const latlngRevers = latlng[1] + ',' + latlng[0];
        this.navigationLink = 'http://maps.google.de/maps?q=' + latlngRevers + '&t=k&z=19';
        this.galleryImages = [];
        for (const image of this.spot.images) {
          this.galleryImages.push({
            big: this.resourceService.resolveUrl(image.filename),
            small: this.resourceService.resolveUrl(image.filename),
            medium: this.resourceService.resolveUrl(image.filename),
            description: image.description
          });
        }
      });
  }

  public navigate() {
    window.open(this.navigationLink);
  }


}
