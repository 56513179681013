import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Category, Guide, Spot} from '../core/model';
import {map} from 'rxjs/operators';
import {ResourceService} from './resource.service';


@Injectable({
  providedIn: 'root'
})
export class GuideService {

  constructor(private http: HttpClient, private resources: ResourceService) {

  }


  get guide(): Guide {
    return this._guide;
  }

  private _guide: Guide;

  private static extractCategory(categoryJson) {
    const category: Category = {} as Category;
    category.name = categoryJson.name;
    category.markerFilename = categoryJson.markerurl;
    category.color = categoryJson.color;
    category.icon = categoryJson.icon;
    return category;
  }

  public async init() {
    this._guide = await this.loadGuide().toPromise();
  }

  private loadGuide(): Observable<Guide> {
    return this.http.get(this.resources.getGuideUrl()).pipe(
      map(json => this.extractGuide(json))
    );
  }

  private extractGuide(json): Guide {

    const guide: Guide = {} as Guide;
    guide._id = json._id;
    guide.title = json.title;
    guide.subtitle = json.subtitle;
    guide.mapcenter = json.mapcenter;
    guide.map_server = json.map_server;
    guide.map_attribution = json.map_attribution;
    const categories = this.extractCategories(json);
    guide.categories = Object.keys(categories).map(function (key) {
      return categories[key];
    });

    const spots = [];
    if (json.spots !== undefined) {
      json.spots.forEach(spotJson => {
        const spot: Spot = {} as Spot;
        spot._id = spotJson._id;
        spot.name = spotJson.name;
        spot.description = spotJson.description;
        spot.images = spotJson.images;
        if (spotJson.geolocation !== undefined) {
          spot.geolocation = spotJson.geolocation;
          spot.category = categories[spotJson.category];
          spots.push(spot);
        }
      });
    }
    guide.spots = spots;
    return guide;
  }

  private extractCategories(json) {
    const categories = {};
    json.categories.forEach(categoryJson => {
      categories[categoryJson.name] = GuideService.extractCategory(categoryJson);
    });
    return categories;
  }


  getSpot(id: string): Spot {
    let spotWithId: Spot = null;
    this._guide.spots.forEach(spot => {
      if (spot._id == id) {
        spotWithId = spot;
      }
    });
    return spotWithId;
  }
}
