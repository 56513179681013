import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {MapComponent} from './components/map/map.component';
import {PreloadAllModules, RouterModule} from '@angular/router';
import {ROUTES} from './routes';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  MatButtonModule, MatCardModule, MatChipsModule, MatFormFieldModule, MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatSelectModule,
  MatSidenavModule,
  MatToolbarModule
} from '@angular/material';
import {SpotComponent} from './components/spot/spot.component';
import {SpotlistComponent} from './components/spotlist/spotlist.component';
import {GuideService} from './services/guide.service';
import {FilterPipe} from './pipes/filter.pipe';
import {FormsModule} from '@angular/forms';
import {TruncatePipe} from './pipes/truncate.pipe';
import {NgxGalleryModule} from 'ngx-gallery';
import {GuideInfoComponent} from './components/guide-info/guide-info.component';


export function init_app(guideService: GuideService) {
  return async () => await guideService.init();
}

@NgModule({
  declarations: [
    AppComponent, MapComponent, SpotComponent, SpotlistComponent, FilterPipe, TruncatePipe, GuideInfoComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatInputModule,
    MatChipsModule,
    MatCardModule,
    MatGridListModule,
    NgxGalleryModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    MatListModule,
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      preloadingStrategy: PreloadAllModules
    })
  ],
  providers: [
    {provide: APP_INITIALIZER, useFactory: init_app, deps: [GuideService], multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
