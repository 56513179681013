import {Component, OnInit} from '@angular/core';
import {GuideService} from '../../services/guide.service';
import {Guide, Spot} from '../../core/model';

@Component({
  selector: 'app-spotlist',
  templateUrl: './spotlist.component.html',
  styleUrls: ['./spotlist.component.css']
})
export class SpotlistComponent implements OnInit {

  guide: Guide;
  spots: Spot[];
  searchText: String = '';

  constructor(private guideService: GuideService) {
    this.guide = guideService.guide;
    this.spots = guideService.guide.spots;
  }

  ngOnInit() {

  }

}
